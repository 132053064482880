import * as React from 'react';

import { observer } from 'mobx-react';
import { PricingCards } from 'components/account/pricing-cards';
// import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';
import { PlainCouponLink } from '../../coupons/coupon-buttons';
// import { Link } from 'react-router-dom';

import __, { pickLocalizedValue } from 'core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { marketingSite } from 'components/nav/path-helpers';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';

const log = createLogger('authenticated-flow');

const Copy = styled('div', {
  '& strong': {
    textStyle: 'body-bold',
  },
});

export const AuthenticatedFlow = observer(() => {
  log.info(`render - selectedL1: ${AppFactory.root.locale}`);
  const { l2 } = AppFactory.root;
  return (
    <div>
      <PricingCards l2={l2} />
      <VSpacer size={12} />
      <PlainCouponLink />
      <VSpacer size={6} />
      <Copy>
        <strong>
          {__('Teaching with Jiveworld?', 'teachingWithJiveworld')}{' '}
        </strong>
        <br />
        <ExternalLink
          href={pickLocalizedValue({
            en: `${marketingSite()}/en/schools/plans`,
            pt: `${marketingSite()}/pt/schools/plans`,
          })}
        >
          {__(
            'Learn about our classroom licenses',
            'learnAboutClassroomLicenses'
          )}
        </ExternalLink>
      </Copy>
      <VSpacer size={8} />
    </div>
  );
});
