import React from 'react';
import {
  StandardPlanPricingCard,
  // PlanPricingCard,
  PlanSubscribeButton,
  // getRibbonOptions,
} from '../plan-pricing-card';
import { Plan } from '@cas-shared/plan';
import { AppFactory } from '@app/app-factory';
// import { PricingCardsWrapper } from './pricing-cards-wrapper';
// import { resolvePlans } from '@core/models/user-manager/user-manager-helpers';
import { LocaleCode } from '@utils/util-types';
import { InlineNotice } from '@naan/inline-notice';
import { styled } from '@naan/stitches.config';
import { useRadio } from '@common/hooks/use-radio';
import { Ribbon } from './ribbon';
import { EduLargeIcon } from '@naan/icons/edu-icon';

import __ from '@core/lib/localization';

interface LoaderState {
  plans?: Plan[];
  failure?: Error;
}

const PricingCardsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  paddingBottom: 24,
  '& .pricing-heading': {
    textStyle: 'medium-heading',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    paddingBottom: 12,
  },
});

const OfflineNotice = () => {
  return (
    <InlineNotice
      type="alert"
      message={__(
        'You are currently offline. Reconnect and refresh to see pricing.',
        'youAreCurrentlyOfflineReconnectAndRefreshToSeePricing'
      )}
    />
  );
};

export const PricingCardsContent = ({ plans }: { plans: Plan[] }) => {
  // const { userManager } = AppFactory.root;
  // const { discountScheme } = userManager.membership;
  const selectedPlan = useRadio<'month' | 'year'>('year');
  const { userManager } = AppFactory.root;
  // const { isStudent } = userManager.accountData;
  const { pricingTierLabel, percentageDiscountLabel, showEducationIcon } =
    userManager.membership;

  if (plans) {
    return (
      <>
        <PricingCardsWrapper>
          {showEducationIcon ? (
            <h3 className="pricing-heading">
              <EduLargeIcon />
              {/* {__('Student pricing', 'studentPricing')} */}
              {pricingTierLabel}
            </h3>
          ) : null}
          {/* {plans.map(plan => {
            const selected = selectedPlan.isCurrent(plan.interval);
            if (!selected) {
              return null;
            }
            const { ribbonTitle, showRibbon } = getRibbonOptions(plan);
            if (!showRibbon) {
              return null;
            }

            return <Ribbon title={ribbonTitle} />;
          })} */}
          {!!percentageDiscountLabel ? (
            <Ribbon title={percentageDiscountLabel} />
          ) : null}
          {plans.map(plan => (
            <StandardPlanPricingCard
              key={plan.slug}
              plan={plan}
              selectAction={selectedPlan.setter(plan.interval as any)}
              selected={selectedPlan.isCurrent(plan.interval)}
              // discountScheme={discountScheme}
            />
          ))}
        </PricingCardsWrapper>
        {plans.map(plan =>
          selectedPlan.isCurrent(plan.interval) ? (
            <PlanSubscribeButton key={plan.slug} plan={plan} /*debug*/ />
          ) : null
        )}
      </>
    );
  }

  return null; // still loading
};

export const PricingCards = ({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const { pricingGroup } = userManager.accountData;
  // const plans: Plan[] = userManager.resolveLocalPlans(l2);
  // const failure = false;

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { plans, failure } = loaderState;

  React.useEffect(() => {
    const { userManager } = AppFactory.root;
    userManager
      .resolveNodePlans(l2)
      .then(plans => setLoaderState({ plans }))
      .catch(error => {
        setLoaderState({ failure: error });
      });
  }, [
    l2,
    pricingGroup /* in case student discount activated via email change */,
  ]);

  if (failure) {
    return <OfflineNotice />;
  }

  if (!plans) {
    return null; // still loading
  }
  return <PricingCardsContent plans={plans} />;
};

//
// to test this anonymous affiliate pricing, use an url in the form of:
//   http://localhost:3100/?utm_medium=alp&utm_source=fojw
//

export const AnonPricingCards = ({ l2 }: { l2: LocaleCode }) => {
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { plans, failure } = loaderState;

  // todo: factor this up with the above
  React.useEffect(() => {
    const { userManager } = AppFactory.root;
    userManager
      .resolveNodePlans(l2)
      .then(plans => setLoaderState({ plans }))
      .catch(error => {
        setLoaderState({ failure: error });
      });
  }, [l2]);

  if (failure) {
    return <OfflineNotice />;
  }

  if (!plans) {
    return null; // still loading
  }

  return (
    <PricingCardsWrapper>
      {plans.map(plan => (
        <StandardPlanPricingCard plan={plan} />
      ))}
    </PricingCardsWrapper>
  );
};
