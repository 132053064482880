import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'study-mode-poster.svg';

const shortVideoId = 'afec42967a99c176e8229cf330893c02';

const en = {
  videoId: 'fcac7d3eef66c6084646a1e11c24bba2',
  poster,
};

const pt = {
  videoId: '6a5d20440ca656f4bec2cbbfec4989ad', // PT 02 - STUDY MODE v3.mp4
  poster,
};

const es = {
  videoId: 'fcac7d3eef66c6084646a1e11c24bba2', // same as english for now
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}
