import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { profileHomePath, subscribePath } from './nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { openUrl } from '@naan/primitives/text/external-link';
import { appConfig } from '@app/config';
import { Message, MessageType } from '@naan/global-messages';
import { embeddedAndroid, updateStoreUrl } from '@core/lib/app-util';
import { formatDate } from '@utils/format-date';
import { UserManager } from '@core/models/user-manager';

import __, { addNbsps } from 'core/lib/localization';

function getPriceIncreaseMessage({
  userManager,
}: {
  userManager: UserManager;
}): Message {
  const fullAccess = userManager.fullAccess;
  const date = addNbsps(formatDate(appConfig.priceIncreaseDate, 'medium'));

  return {
    type: 'announcement',
    message: fullAccess
      ? `Prices are going up on ${date}. Renew your subscription today to keep your current rate.`
      : `LIMITED TIME OFFER: Lock in your subscription before prices go up on ${date}.`,

    renderLink: () => {
      return (
        <Link
          to={
            fullAccess
              ? profileHomePath() + '?price-increase=1'
              : subscribePath()
          }
          rel="noreferrer"
        >
          {__('Learn more', 'learnMore')}
        </Link>
      );
    },
    elementId: 'price-increase-message',
    onDismiss: () => userManager.dismissPriceIncreaseAnnouncement(),
    dismissible: true,
  };
}

export function useLearnDashboardMessages() {
  const navigate = useNavigate();
  const { userManager, localState, shouldUpdateNative } = AppFactory.root;
  const { membership } = userManager;
  const {
    autoRenewFailed,
    accessExpired,
    statusKey,
    hasAffiliateDerivedPricing,
    showPriceIncreaseBanner,
  } = membership;

  // const { accountData, accessExpired } = userManager;
  // const { showClassroomLicenseNag, paymentData } = accountData;

  const {
    // needsBrownListedBrowserWarning,
    needsIosUnsupportedWarning,
    needsIosUpgradeWarning,
  } = localState;

  const currentMessage = React.useMemo<Message>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const testMessage = urlParams.get('test-msg') as MessageType | null;

    if (testMessage) {
      return {
        type: testMessage,
        message: 'This is a test message. It will not appear in production.',
        elementId: 'test-message',
      };
    }

    if (autoRenewFailed) {
      // assign then return for better typing
      const result: Message = {
        type: 'warning',
        message: __(
          'Your card payment could not be charged',
          'yourCardPaymentCouldNotBeCharged'
        ),
        action: {
          label: __('My account', 'myAccount'),
          callback: () => navigate(profileHomePath()),
        },
        elementId: 'auto-renew-failed-message',
      };
      return result;
    }

    if (hasAffiliateDerivedPricing && statusKey === 'trial') {
      const message = userManager.accountData.affiliateWelcomeHeading;
      if (message) {
        return {
          type: 'success',
          message, //: __( "You've unlocked special pricing", 'youveUnlockedSpecialPricing' ),
          action: {
            label: __('Subscribe', 'subscribe'),
            callback: () => navigate(subscribePath()),
          },
        };
      }
    }

    if (showPriceIncreaseBanner) {
      return getPriceIncreaseMessage({ userManager });
    }

    if (accessExpired) {
      const result: Message = {
        type: 'info',
        message: __('Your full access has expired', 'yourFullAccessHasExpired'),
        action: {
          label: __('My account', 'myAccount'),
          callback: () => navigate(profileHomePath()),
        },
        elementId: 'access-expired',
      };
      return result;
    }

    if (shouldUpdateNative) {
      const label = embeddedAndroid()
        ? __('Go to Play Store', 'gotoPlayStore')
        : __('Go to App Store', 'gotoAppStore');

      const result: Message = {
        type: 'warning',
        message: __('Update available', 'updateAvailable'),
        action: {
          label,
          callback: () => openUrl(updateStoreUrl()),
        },
        dismissible: false,
        elementId: 'update-available-message',
      };
      return result;
    }

    if (needsIosUpgradeWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'For the best experience, update this device to iOS 16.4 or higher in the Settings app.',
          'forTheBestExperienceUpdateThisDeviceToIos164'
        ),
        // action: {
        //   label: __('View', 'view'),
        //   callback: () => openUrl(appConfig.website.urls.browserSupport),
        // },
        dismissible: true,
        onDismiss: () => localState.dismissUpgradeOsWarning(),
        elementId: 'upgrade-os-message',
      };
      return result;
    }

    if (needsIosUnsupportedWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'Unsupported iOS version. Please check if an iOS update is available in the Settings app.',
          'unsupportedIosVersionPleaseCheckIfAnIosUpdate'
        ),
        elementId: 'unsupported-ios-message',
        // action: {
        //   label: __('View', 'view'),
        //   callback: () => openUrl(appConfig.website.urls.browserSupport),
        // },
      };
      return result;
    }

    // // disabling the desktop safari warning for now
    // if (needsBrownListedBrowserWarning && false) {
    //   const result: Message = {
    //     type: 'warning',
    //     message: __(
    //       'See the recommended web browsers for Jiveworld',
    //       'brownListedWarning'
    //     ),
    //     action: {
    //       label: __('View', 'view'),
    //       callback: () => openUrl(appConfig.website.urls.browserSupport),
    //     },
    //     dismissible: true,
    //     onDismiss: () => localState.dismissBrownListedBrowserWarning(),
    //     elementId: 'brown-listed-browser-message',
    //   };
    //   return result;
    // }

    return undefined;
  }, [
    autoRenewFailed,
    showPriceIncreaseBanner,
    statusKey,
    hasAffiliateDerivedPricing,
    accessExpired,
    shouldUpdateNative,
    needsIosUpgradeWarning,
    needsIosUnsupportedWarning,
    // needsBrownListedBrowserWarning,
    navigate,
    userManager,
    localState,
  ]);

  return currentMessage;
}

export function useClassroomDashboardMessages(): Message | null {
  // const navigate = useNavigate();
  // const { userManager } = AppFactory.root;
  // const { accountData } = userManager;
  // const { showClassroomLicenseNag } = accountData;

  // // const currentMessage = React.useMemo<Message>(() => {
  //   if (showClassroomLicenseNag) {
  //     const result: Message = {
  //       type: 'error',
  //       message: __(
  //         'A classroom has exceeded its license quota.',
  //         'aClassroomHasExceeded'
  //       ),
  //       elementId: 'classroom-license-nag-message',
  //     };
  //     return result;
  //   }

  //   return undefined;
  // }, [showClassroomLicenseNag]);

  // return currentMessage;

  /// no classroom messages for now
  return null;
}
