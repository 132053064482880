import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { SoundbitesStoryBadge } from './soundbite-story-badge';
import { ShortsBadge } from './shorts-badge';
import { VocabStoryBadge } from './vocab-story-badge';

const StoryCardMetaWrapper = styled('div', {
  marginBottom: '$1',
  lineHeight: '20px',
  textStyle: 'small-text',
  display: 'flex',
  alignItems: 'center',
  gap: 4,

  '& > .meta-text': {
    textStyle: 'small-text',
    color: '$textSecondary',
  },

  '& .channel-title': {
    textStyle: 'small-text-bold',
  },
});

export const StoryCardMeta = ({ story }: { story: Story }) => {
  return (
    <StoryCardMetaWrapper>
      {story.channel ? (
        <span className="meta-text channel-title">{story.channel.title}</span>
      ) : null}
      <span className="meta-text duration-description">
        {story.simpleDurationDescription}
      </span>
      <VocabStoryBadge story={story} />

      {story.isShort ? <ShortsBadge /> : null}

      <SoundbitesStoryBadge story={story} />
    </StoryCardMetaWrapper>
  );
};
