import { observable } from 'mobx';
import Dayjs from 'dayjs';
import {
  applySnapshot,
  getRoot,
  ModelTreeNode,
  snap,
  volatile,
} from 'ts-state-tree/tst-core';
import { camelCasify, objectFromUrlQuery } from 'common/object-from-url-query';
import { bindery } from 'ts-state-tree/tst-core';
import { stringToBool } from '@utils/string-utils';
import * as loggly from 'legacylib/loggly';
import {
  alertWarningError,
  bugsnagNotify,
  // notifySuccess,
} from 'app/notification-service';
import { AssetCacher } from 'lib/asset-cacher';
import { AppFactory } from '@app/app-factory';
import { AppStateCacher } from 'lib/app-state-cacher';
import { appConfig } from 'app/env';
import { ApiInvoker } from '../services/api-invoker';
import { UserManager } from './user-manager/user-manager';
import { StoryManager } from './story-manager';
import { alertSevereError } from '@app/notification-service';
import tstSchema from '@app/tst-schema.json';
import { createLogger } from 'app/logger';
import { LocalState, UtmData } from './local-state';
import { track } from '@app/track';
import * as meta from 'common/analytics/meta-analytics';
// import { setVideoAutoplay } from 'components/ui/video-player/player-view-controller';
import { notEmpty } from '@utils/conditionals';
import { setErrorContext } from '@common/error-reporting';
import {
  buildInfo,
  embeddedAndroid,
  embeddedBuildNum,
  embeddedIos,
  embeddedMode,
  persistEmbedState,
} from '@core/lib/app-util';
import { setAnalyticsContext } from 'app/track';
import { /*defaultCatalogSlugForL2,*/ GlobalSettings } from './global-settings';
import { UserDataSyncImpl } from '@core/services/user-data-sync-impl';
import { SettingsSyncImpl } from '@core/services/settings-sync-impl';
import { CatalogMetaSyncImpl } from '@core/services/catalog-meta-sync-impl';
import { initializeFirebaseConnection } from '@core/services/firebase-init';
import { getInstallationId, hasBeenVisited } from './installation-id';
import { isNetworkError } from '@core/lib/error-handling';
import { decorateUrl, UrlOptions } from 'components/nav/decorate-url';
// import { GlobalSettings } from '@core/services/settings-sync';
// import minibus from 'common/minibus';
// import { sleep } from '@utils/util';
import '@common/bugsnag/bug-reporting-init'; // import the expanded 'window' interface
import { Insets } from 'native-support/insets';
import {
  cancelUpdatePolling,
  startUpdatePolling,
} from '../../pwa/window/update-checker';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { LocaleCode } from '@utils/util-types';
import { Currency } from '@cas-shared/cas-types';
import { /*welcomePath,*/ homePath } from 'components/nav/path-helpers';
import __, { setLocale } from '@core/lib/localization';
// import { embeddedIosColdstart } from 'native-support/init-embed-state';
// import { sleep } from '@utils/util';

const log = createLogger('root');
// const { forcedL2 } = appConfig;

// @armando, do you understand why our use of enums trigger lint errors?
export const enum AppInitStatus {
  // eslint-disable-next-line no-unused-vars
  INITIALIZING = 'INITIALIZING',
  // eslint-disable-next-line no-unused-vars
  READY = 'READY',
  // eslint-disable-next-line no-unused-vars
  STARTUP_FAILURE = 'STARTUP_FAILURE',
  // OFFLINE = 'OFFLINE';
}

/**
 * removes the query string from the location bar address
 */
const clearQuery = (preservedParams: UrlOptions) => {
  var baseUrl = window.location.href.split('?')[0];
  const newUrl = decorateUrl(baseUrl, preservedParams);
  window.history.replaceState({}, '', newUrl);
};

const APP_INIT_SHORT_TIMEOUT_MS = 5000; // report mixpanel if takes too long to init
const APP_INIT_LONG_TIMEOUT_MS = 30000;

export class AppRoot extends ModelTreeNode {
  static CLASS_NAME = 'AppRoot' as const;

  private static bound: boolean = false;

  public static create(
    snapshot: any = {} /*, dependencies: any = {}*/
  ): AppRoot {
    AppRoot.ensureBound();
    const model = super.create(AppRoot, snapshot) as AppRoot;
    return model;
  }

  public static ensureBound(): void {
    if (!AppRoot.bound) {
      AppRoot.bound = true;
      bindery.mergeSchemaDefinitions(tstSchema as any);
      bindery.bind(AppRoot);
      bindery.bind(LocalState);
      bindery.bind(GlobalSettings);
      UserManager.bindModels(bindery);
      StoryManager.bindModels(bindery);
      bindery.compileBindings();
    }
  }

  @volatile
  @observable.ref // @observable.not
  apiInvoker: ApiInvoker; // delegate for rails server REST API invocations

  localState: LocalState = snap({});

  globalSettings: GlobalSettings = snap({});

  userManager: UserManager = snap({});

  // unified with the catalog data
  storyManager: StoryManager = snap({});

  @volatile
  status: AppInitStatus;

  @volatile
  offline: boolean = false;

  @volatile
  hidden: boolean = false;

  // set to true within the white-listed screens (dashboard, story list, sb cal, stats)
  // update prompt will be deferred when not enabled
  @volatile
  updatePromptEnabled: boolean = false;

  // assigned when updated detected while prompt is disabled
  // 'sw' = service worker managed flow
  // 'dumb' = dumb reload (ios webview)
  // fascilitates deferring the prompt until a permissable screen is navigated to
  @volatile
  updatePromptPending?: 'sw' | 'dumb' | null = null;

  @volatile
  showingL2SwitchGuard: boolean = false;

  // arbitrary text to show in the pie menu
  @volatile
  debugStatus: string = null;

  // hack to make it easy to see the mostly recently consumed logs
  @volatile
  serviceWorkerLogs: string[] = [];

  @volatile
  standalone: boolean = false;

  // true when no persisted state exists
  // used to direct first time native app users to the branded welcome screen instead of dashboard
  @volatile
  firstVisit: boolean = false;

  // window.location.href upon app init
  @volatile
  initHref: string;

  @volatile
  forceHidePiMenu: boolean = false;

  // // support transient override of UI locale
  // @volatile
  // localeOverride?: LocaleCode;

  // state set while on account and l2-switch guard screens to enable activation
  // of the userData.selectedL1 locale instead of catalog driven locale
  @volatile
  accountLocaleActive: boolean = false;

  // transient override for development and testing
  @volatile
  overridenL1: LocaleCode;

  // transient hack to test pricing card permutations
  @volatile
  selectedCurrency: Currency;

  // transient flag for stripe testing
  @volatile
  dailySubscriptionEnabled: boolean;

  get offlineOrHidden(): boolean {
    return this.offline || this.hidden;
  }

  async appInitialize() {
    log.debug('appInitialize');

    // this only seemed to be relevant to ios 15.x, so omitting for now
    // if (embeddedIosColdstart()) {
    //   log.debug(`embeddedIosColdstart - reloading`);
    //   const baseUrl = window.location.href.split('?')[0];
    //   window.location.href = baseUrl;
    //   await sleep(500); // attempt to avoid flash of pi menu
    //   return;
    // }

    this.status = AppInitStatus.INITIALIZING;

    this.bindMiscErrorContextResolver();
    this.initializeSyncManagers();

    this.checkIfStandalone();
    this.startPwaListeners();

    try {
      this.initHref = window.location?.href;
      this.firstVisit = !hasBeenVisited();
      log.debug(`firstVisit: ${String(this.firstVisit)}`);

      // capture what we can as soon as we have the anonymous installationId
      this.setReportingContext();

      this.apiInvoker = new ApiInvoker({
        apiEnv: appConfig.apiEnv,
        authToken: this.userManager.token, // todo: cleanup, this is probably always null at this stage
        // revisit once we look again at marketing campaigns
        // appInstallAttribution: this.globalConfig.appInstallAttribution, // not sure if relevant
      });

      track('system__app_init_start', { href: this.initHref });
      meta.trackEvent('system__app_init_start', { href: this.initHref });

      setTimeout(() => {
        if (this.status === AppInitStatus.INITIALIZING) {
          track('system__app_init_short_timeout', {
            timeout: APP_INIT_SHORT_TIMEOUT_MS,
          });
        }
      }, APP_INIT_SHORT_TIMEOUT_MS);
      setTimeout(() => {
        if (this.status === AppInitStatus.INITIALIZING) {
          track('system__app_init_long_timeout', {
            timeout: APP_INIT_LONG_TIMEOUT_MS,
          });
        }
      }, APP_INIT_LONG_TIMEOUT_MS);
      await this.initState();
      // todo: consider more carefully when we can safely render the dashboard
      this.status = AppInitStatus.READY;
      track('system__app_init_ready');

      // perform non-critical initialization steps asynchronously
      this.postInit().catch(bugsnagNotify);
    } catch (error) {
      // will get triggered due to firebase error if offline and no local storage, which is appropriate
      log.info(`app init error: ${error}`);
      this.status = AppInitStatus.STARTUP_FAILURE;
      alertSevereError({ error, note: 'app-root - appInitialize' });
      track('system__app_init_failure', { error: String(error) });
    }
    log.info('root store created');
  }

  // hack to resolve the dynamic properties to augment sentry reports with.
  // loosly binding to reduce the sentry init dependencies which could be fatal before the
  // error handling is initialized
  bindMiscErrorContextResolver() {
    log.debug('bindMiscErrorContextResolver');
    window.miscErrorContextResolver = () => {
      // capture properties which might change since the last call to setReportingContext
      return {
        offline: this.offline,
        firebaseStatus: AppFactory.firebaseConnection?.status,
      };
    };
  }

  async postInit() {
    log.info('postInit');

    // particularly important in the short-term to extend the expiry of the one-week ios cookies
    this.userManager.refreshUserTokenCookieIfNeeded().catch(bugsnagNotify);

    // if (this.userManager.userData.hasBorkedMigrationData) {
    //   await this.userManager.userData.repairBorkedProgressData();
    // }

    // // don't auto migrate all yet, it can be very slow for some users
    // // and i saw some firebase write stream queue errors when testing.
    // // todo: run migration in small batches
    // if (this.userManager.userData.hasVocabMigrationPendingProgresses) {
    //   this.migrateAllPendingBogotaVocabs().catch(bugsnagNotify);
    // }

    // a/b test skipping of firebase connection init
    if (this.firebaseForceDisconnected) {
      AppFactory.firebaseConnection.status = 'DISCONNECTED';
      log.info(`firebase force disconnected`);
      track('system__firebase_init_disconnected');
      return;
    }

    try {
      log.info('before initializeFirebaseConnection');
      await this.initializeFirebaseConnection();
      this.setReportingContext();
      track('system__firebase_init_ready');
      log.info('after initializeFirebaseConnection');
    } catch (error) {
      log.error(`initializeFirebaseConnection error: ${error}`);
      log.error((error as Error)?.stack);
      // expecting firebase to fail right now for some users, so just log to mixpanel, not sentry
      // bugsnagNotify(error as Error);
      AppFactory.firebaseConnection.status = 'ERROR';
      track('system__firebase_init_error', { error: String(error) });
      this.setReportingContext();
    }
  }

  // called when browser tells us we're offline (not completely trustable)
  becameOffline() {
    this.offline = true;
    AppFactory.analyticsManager.offline();
    // cancelUpdatePolling();
  }

  // called when we were previously online and become offline
  becameOnline() {
    this.offline = false;
    AppFactory.analyticsManager.online();
    AppFactory.assetCacher.attemptReenable();
    // proactively flush changes when disconnected and coming back online
    this.userManager.syncIfDeferred().catch(bugsnagNotify);
    // startUpdatePolling();
  }

  // when tab switched away, window fully covered, application switched away
  becameHidden() {
    log.debug('becameHidden');
    this.hidden = true;
    // root.stopGlobalDataListen(); // WIP
    this.userManager?.stopListen();
    // todo: consider persisting here (so we can be a bit less aggressive within the study player)
    cancelUpdatePolling();
  }

  // called when visible again after being hidden
  becameVisible() {
    log.debug('becameVisible');
    this.hidden = false;
    // root.startGlobalDataListen(); // WIP
    this.userManager?.startListen();
    this.userManager?.refreshAccountDataIfStale()?.catch(error => {
      log.warn('refreshAccountDataIfStale - error', error);
      bugsnagNotify(error);
    });
    startUpdatePolling();
  }

  // different behaviors when we startup offline
  initializeOffline(offline: boolean) {
    this.offline = offline;
    if (offline) {
      AppFactory.analyticsManager.offline();
    }
  }

  get firebaseForceDisconnected(): boolean {
    const sampleRate = appConfig.firebase?.forceDisconnectedSampleRate || 0;
    return Math.random() < sampleRate;
  }

  checkIfStandalone() {
    if (window.matchMedia?.('(display-mode: standalone)').matches) {
      this.standalone = true;
    }
  }

  startPwaListeners() {
    window.addEventListener('appinstalled', evt => {
      track('system__pwa_installed');
      this.standalone = true;
    });
  }

  get installFlavor(): 'browser' | 'native' | 'pwa' {
    if (embeddedMode()) {
      return 'native';
    }
    if (this.standalone) {
      return 'pwa';
    }
    return 'browser';
  }

  // // default l2 based on currently loaded catalog
  // get l2(): LocaleCode {
  //   const result =
  //     appConfig.forcedL2 ||
  //     this.storyManager.l2 ||
  //     this.userManager.userData.selectedL2 ||
  //     appConfig.defaultL2;
  //   return result;
  // }

  get l2(): LocaleCode {
    return /*appConfig.forcedL2 ||*/ ReturnNavState.l2;
  }

  get l1(): LocaleCode {
    // return this.userManager.userData.selectedL1 || this.storyManager.l1 || 'en';
    return this.overridenL1 || this.storyManager.l1 || 'en';
  }

  overrideL1(locale: LocaleCode) {
    this.overridenL1 = locale;
    this.applyLocale();
  }

  get currency(): Currency {
    const accountCurrency = this.userManager.nodeAccountData?.currency;
    if (accountCurrency) {
      return accountCurrency;
    }
    if (this.selectedCurrency) {
      return this.selectedCurrency;
    }
    // return this.l1 === 'pt' ? 'brl' : 'usd';
    return this.l2 === 'en' ? 'brl' : 'usd';
  }

  get raBranding(): boolean {
    return this.l2 === 'es';
  }

  get countryTagsEnabled(): boolean {
    return this.l2 === 'es';
  }

  get apIbTagsEnabled(): boolean {
    return this.l2 === 'es';
  }

  get isMultiChannel(): boolean {
    return this.l2 === 'en';
  }

  get availableL2s(): LocaleCode[] {
    // if (forcedL2) {
    //   return [forcedL2];
    // } else {
    // todo: derive from appConfig.catalogs, genericize the sorting when additional products supported
    if (this.l2 === 'es') {
      return ['es', 'en'];
    } else {
      return ['en', 'es'];
    }
    // }
  }

  subBrand(presentation: 'short' | 'normal' = 'normal') {
    if (this.l2 === 'en') {
      return presentation === 'short' ? 'en' : 'english';
    } else {
      return presentation === 'short' ? 'es' : 'español';
    }
  }

  get productName(): string {
    return this.productNameForL2(this.l2);
  }

  productNameForL2(l2: LocaleCode): string {
    return l2 === 'en' ? 'Jiveworld English' : 'Jiveworld Español';
  }

  get l2Localized(): string {
    return this.l2 === 'en'
      ? __('English', 'english')
      : __('Spanish', 'spanish');
  }

  // overrideLocale(locale: LocaleCode) {
  //   this.localeOverride = locale;
  //   this.applyLocale();
  // }

  activateAccountLocale() {
    this.accountLocaleActive = true;
    this.applyLocale();
  }

  deactivateAccountLocale() {
    this.accountLocaleActive = false;
    this.applyLocale();
  }

  get locale(): LocaleCode {
    const { l1, l2, accountLocaleActive, userManager } = this;
    const { immersiveViewEnabled } = userManager.userData.userSettings;

    if (accountLocaleActive) {
      return l1;
    }
    return immersiveViewEnabled ? l2 : l1;
  }

  applyLocale() {
    log.info(`applyLocale: ${this.locale}`);
    setLocale(this.locale);
  }

  selectCurrency(currency: Currency) {
    this.selectedCurrency = currency;
  }

  get today(): Dayjs.Dayjs {
    return this.storyManager.today;
  }

  /**
   * automatically called after creation
   */
  async initState(): Promise<void> {
    log.debug(`initState`);

    // this.setStatus('initializing');

    const appStateCacher = await AppStateCacher.create('jw:app-state');
    AppFactory.setAppStateCacher(appStateCacher);

    try {
      await this.localState.load();
      // todo: consider also persisting and loading global settings
    } catch (error) {
      alertWarningError({ error });
    }

    // needed so review mode is honored before dashboard is first rendered
    await this.refreshGlobalSettings(); // has own try/catch

    try {
      await this.storyManager.loadLocal();
    } catch (error) {
      alertWarningError({
        error,
        note: 'root.initState - storyManager.loadLocal',
      });
    }

    try {
      const loaded = await this.userManager.loadLocal();
      if (loaded) {
        this.setReportingContext(); // update with user info when available locally
      }
      // make sure to record any prior session data that wasn't cleanly exited
      // recordOrphanedSessionData();
    } catch (error) {
      alertWarningError({
        error,
        note: 'root.initState - userManager.loadLocal',
      });
    }

    const { forceError } = await this.handleQueryParams();

    if (this.userManager.authenticated) {
      // execute the account data refresh asynchronously from the rest of the app init when we have cached user data
      this.userManager.initAuthenticatedWithLocalData().catch(error => {
        log.error(`error during async initWithLocalData - will reset auth`);
        alertSevereError({ error });
        this.userManager.resetAuthentication();
      });
    } else {
      try {
        // attempt from server cookie if available
        await this.authFromStoredToken();
      } catch (error) {
        alertSevereError({ error, note: 'root.initState - userManager.init' });
        this.userManager.resetAuthentication();
      }
    }

    const { userData } = this.userManager;
    if (userData.selectedL2 && userData.selectedL2 !== this.l2) {
      log.info(
        `user selectedL2 (${this.userManager.userData.selectedL2}) mismatched from uri l2 (${this.l2}) - resetting selectedL2`
      );
      // if (this.userManager.authenticated) {
      //   this.showL2SwitchGuard();
      // } else {
      // force clear immersive view mode when anonymous and switching l2's
      ReturnNavState.clearL2Cookie();
      this.userManager.userData.userSettings.immersiveViewEnabled = false;
      await this.userManager.userData.selectL2(this.l2);
      // }
    }

    if (!this.userManager.authenticated) {
      // if (this.userManager.userData.selectedL2 !== this.l2) {
      //   log.info(
      //     `user selectedL2 (${this.userManager.userData.selectedL2}) mismatched from uri l2 (${this.l2}) - resetting selectedL2`
      //   );
      //   // force clear immersive view mode when anonymous and switching l2's
      //   this.userManager.userData.userSettings.immersiveViewEnabled = false;
      //   await this.userManager.userData.selectL2(this.l2);
      // }
      // const catalogL2 = this.storyManager.l2;
      // if (catalogL2 && catalogL2 !== this.l2) {
      // }

      if (
        notEmpty(this.userManager.resolveAffiliateCode()) &&
        !this.userManager.accountData.affiliateSlug
      ) {
        log.info('pending affiliate code found, refreshing account data');
        await this.userManager.refreshAccountData(); // todo: should be able to use local logic here now
      }

      log.info('initState - anonymous reporting context');
    }
    // this.userManager.ensureWelcomeTipsAssigned();

    // setup anonymous context when not auto-logged in, redundantantly set after fetching account data when authenticated
    this.setReportingContext();

    // make sure we have at least some version of the catalog before rendering the dashboard
    // await this.storyManager.ensureCatalogNoFirestore(this.catalogSlug);
    if (this.storyManager.isEmpty) {
      log.warn(`initState - empty catalog data`);
      // log.info(`empty catalog data - loading globalSettings default`);
      // await this.storyManager.ensureCatalogUrl(
      //   this.globalSettings.defaultCatalogUrl
      // );
      await this.storyManager.ensureCatalogSlug(this.catalogSlug);
    } else {
      if (
        this.storyManager.l2 !== this.l2 ||
        this.storyManager.l1 !== this.l1
      ) {
        log.warn(
          `initState - uri l2/l1 mismatch from catalog l2/l1: ${this.storyManager.l2}/${this.storyManager.l1} != ${this.l2}/${this.l1}`
        );
        await this.storyManager.ensureCatalogSlug(this.catalogSlug);
      }
    }

    ReturnNavState.reset(/* this.l2 */);
    this.applyLocale();

    // // make sure we're subscribed (might be redundant)
    // this.storyManager.subscribeToCatalogSlug(this.catalogSlug);

    // // simple schema updates checked here and by userManager.applyAuthentication
    // try {
    //   const dirty = this.userManager.userData.migrateSimpleSchemaChanges();
    //   if (dirty) {
    //     log.info(`persisting simple schema updates`);
    //     await this.userManager.persistUserData();
    //   }
    // } catch (error) {
    //   alertWarningError({
    //     error,
    //     note: 'root.init - migrateSimpleSchemaChanges',
    //   });
    // }

    if (this.localState.logglyEnabled) {
      loggly.activate();
    }

    // for the moment, globally disable autoplay
    // if (this.localState.videoAutoplay) {
    //   setVideoAutoplay(true);
    // }

    if (
      this.localState.embeddedPlatform ||
      this.localState.embeddedBuildNumber
    ) {
      log.info(`migrating localState embedded params`);
      if (!window.embedState.platform) {
        window.embedState.platform = this.localState.embeddedPlatform;
      }
      if (!window.embedState.buildNum) {
        window.embedState.buildNum = Number(
          this.localState.embeddedBuildNumber
        );
      }
      persistEmbedState();
      this.localState.clearV80EmbeddedParams().catch(bugsnagNotify);

      // only relevant during the 8.0 -> 8.1 transition
      if (window.embedState.buildNum >= 8010000 && Insets.top) {
        bugsnagNotify(
          `8.1 embed with top inset assigned - forcing reload, build: ${String(
            window.embedState.buildNum
          )}, top: ${String(Insets.top)}}`
        );
        window.embedState.insetTop = 0;
        persistEmbedState();

        // don't risk a forced reload
        // alertWarning('8.1 embed w/ top inset - reloading');
        // // give the sentry report a chance to get delivered before the reload
        // setTimeout(() => reloadOrNativeReset(), 250);
      }
    }

    if (forceError) {
      throw Error('Debugging: forceError triggered');
    }

    // delay the asset cacher init until we hopefully have more reporting context
    const forceDisabled =
      !this.userManager.userData.userSettings.autoDownloadEnabled;
    AppFactory.setAssetCacher(
      await AssetCacher.create('jw:story-assets', { forceDisabled })
    );

    // needed to trigger new soundbite each midnight
    this.storyManager.refreshDateAtMidnight();

    // roll back the caching aggressiveness until we ensure stability
    // /*async*/ this.storyManager.ensureCacheState();
    // } catch (error) {
    //   if (error instanceof NetworkError) {
    //     this.setOffline();
    //   } else {
    //     alertWarningError({ error, note: 'root.initState' });
    //     this.setStartupFailure();
    //   }
    // }
  }

  /**
   * if there's a query var in the url like
   * `token=eyJabc123.eyJabc123456`
   * it will grab that and store it locally
   */
  async handleQueryParams() {
    const {
      token = null,
      invite,
      debug,
      selectedL1,
      forceError,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      googleAuth,
      flow,
      pi,
    } = objectFromUrlQuery<{
      token?: string;
      invite?: string;
      debug?: string;
      selectedL1?: LocaleCode;
      forceError?: string;
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmTerm?: string;
      utmContent?: string;
      googleAuth?: string; // hack to support exposing the google auth button for one particular school
      flow?: string;
      pi?: string;
    }>();

    if (googleAuth) {
      log.info('google auth param', googleAuth);
      const enabled = stringToBool(googleAuth);
      await this.localState.storeGoogleAuthEnabled(enabled);
    }

    if (token) {
      log.info(`url token: ${token}`);

      if (token !== this.userManager.token) {
        log.warn(
          `location token mismatched from local store data - resetting local store`
        );
        // todo: should perhaps move this logic back up to the main appInit function
        log.debug(`saving token into cookie: ${token}`);
        await this.userManager.setUserTokenCookie(token);
        // const confirmSaved = await this.userManager.getServerCookieUserToken();
        // log.debug('refetched server cookie token: ${confirmSaved');
        // if (confirmSaved !== token) {
        //   log.error(`beware failed to save server cookie`);
        // }
        await this.userManager.resetLocalData();
      }
    }

    // if (locale) {
    //   await this.localState.storeLocale(locale);
    // } else {
    //   this.localState.applyLocale();
    // }

    if (selectedL1) {
      await this.userManager.userData.selectL1(selectedL1);
    }

    if (debug !== undefined) {
      await this.localState.storeForceDevToolsEnabled(stringToBool(debug));
    }
    if (invite !== undefined) {
      await this.validateInvite(invite);
    }
    const referrer = document.referrer;
    const hasUtm =
      utmSource || utmMedium || utmCampaign || utmTerm || utmContent;
    if (hasUtm) {
      const utmData: UtmData = {
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
        referrer,
      };
      // drives mixpanel event properties
      await this.localState.storeLatestUtmData(utmData);
      // used by rails server to attribute newly registered users
      await this.userManager.setTrafficSourceCookie(utmData);
    } else {
      if (referrer) {
        const existing = this.userManager.getTrafficSourceCookie();
        if (existing) {
          log.info(
            `ignoring referrer: ${referrer}, existing trafficSource: ${JSON.stringify(
              existing
            )}`
          );
          if (this.localState.emptyLatestUtmData) {
            const utmData = camelCasify(existing) as UtmData;
            log.debug(
              `pulling traffic source cookie data into local state: ${JSON.stringify(
                utmData
              )}`
            );
            await this.localState.storeLatestUtmData(utmData);
          }
        } else {
          await this.userManager.setTrafficSourceCookie({ referrer });
        }
      }
    }

    if (hasUtm || token || invite || debug || forceError !== undefined) {
      // the 'flow' param is passed in for some links from the marketing site and need to
      // preserved into the query string so that it can get included in the page tracking props
      const preservedParams = { flow }; // empty value will get stripped out later // !!flow ? { flow } : {};
      clearQuery({ search: preservedParams });
    }

    if (pi && pi !== 'true') {
      this.forceHidePiMenu = true;
    }

    return { token, invite, debug, forceError };
  }

  // this has gotten a bit convoluted, but flip both flags for convenience
  togglePiMenu() {
    this.toggleForceHidePiMenu();
    this.localState
      .storeForceDevToolsEnabled(!this.forceHidePiMenu)
      .catch(bugsnagNotify);
  }

  toggleForceHidePiMenu() {
    this.forceHidePiMenu = !this.forceHidePiMenu;
  }

  toggleDailySubscription() {
    this.dailySubscriptionEnabled = !this.dailySubscriptionEnabled;
  }

  /**
   * if we have a locally stored token we use that to log the user in.
   * only expected now the first time a user loads the new site
   */
  async authFromStoredToken() {
    // const serverCookieToken = await this.userManager.getServerCookieUserToken();
    const token = this.userManager.getUserTokenCookie();
    if (token) {
      try {
        log.info(`auto login with server cookie user token`);
        await this.userManager.autoLogin(token);
        return;
      } catch (error) {
        alertWarningError({ error, note: 'root.authFromStoredToken' });
        await this.userManager.reset();
      }
    }
  }

  get userSettings() {
    return this.userManager.userData.userSettings;
  }

  get inviteNeeded(): boolean {
    const { inviteGateEnabled } = appConfig;

    return (
      inviteGateEnabled &&
      !this.localState.validatedInviteCode &&
      !this.userManager.authenticated
      // !this.userManager.validatedInviteCode // legacy state
    );
  }

  get loadingData() {
    return this.apiInvoker?.loadingData || this.userManager?.loadingUserData;
    // tried to use this to drive loading indicator just after logging in, but didn't seem to work
    // (this.userManager.authenticated && !this.userManager.loggedInAndReady)
  }

  setReportingContext() {
    const data: any = {
      website: appConfig.website.baseUrl,
      apiEnv: appConfig.apiEnv,
      // installationId: this.localState.installationId,
      installationId: getInstallationId(),
      userManager: this.userManager.reportingContextData,
      accountData: this.userManager.accountData.reportingContextData,
      localState: this.localState.snapshot,
      buildInfo: buildInfo(),
      miscInfo: {
        installFlavor: this.installFlavor,
        standalone: this.standalone,
        firstVisit: this.firstVisit,
        apiEnv: appConfig.apiEnv,
        website: appConfig.website.baseUrl,
        // could be stale, should figure out best way to add this just-in-time
        firebaseStatus: AppFactory.firebaseConnection.status,
        wakelock: AppFactory.wakeLock.type,
        catalogUrl: this.storyManager.catalogUrl,
      },
    };

    log.info(`setReportingContext - ${data?.accountData?.email}`);

    setErrorContext(data);

    // provides context for implicity properties with future event tracking
    // (no longer side effects indentity operations)
    setAnalyticsContext(data);
  }

  get catalogSlug() {
    // if (appConfig.forcedCatalog) {
    //   return appConfig.forcedCatalog;
    // }

    // const { userData } = this.userManager;
    // if (userData.overrideCatalogSlug) {
    //   return userData.overrideCatalogSlug;
    // }

    // similar to this.l2, except bypasses currently loaded catalog
    // const l2 = appConfig.forcedL2 || userData.selectedL2 || appConfig.defaultL2;
    const l2 = ReturnNavState.l2;
    const catalogLookup = appConfig.catalogs[l2];
    if (!catalogLookup) {
      throw Error(`catalog lookup not found for l2: ${l2}`);
    }
    const l1 = this.userManager.userData.selectedL1 || catalogLookup.defaultL1;
    let slug = catalogLookup[l1] || catalogLookup[catalogLookup.defaultL1];
    if (!slug) {
      throw Error(`catalog slug not found for l2: ${l2}, l1: ${l1}`);
    }
    log.info('catalogSlug', slug);
    return slug;
  }

  showL2SwitchGuard() {
    log.warn('showL2SwitchGuard');
    this.showingL2SwitchGuard = true;
  }

  clearL2SwitchGuard() {
    log.info('clearL2SwitchGuard');
    this.showingL2SwitchGuard = false;
  }

  get shouldUpdateNative(): boolean {
    if (!embeddedMode()) {
      return false;
    }

    const currentBuildNum = embeddedBuildNum();
    const newBuildNum = this.storeBuildNumber;
    log.debug(
      `shouldUpdateNative - current: ${String(currentBuildNum)}, new: ${String(
        newBuildNum
      )}`
    );
    if (!currentBuildNum || !newBuildNum) {
      // // [old comment] this apparently can get triggered somehow when logging out and was breaking the unit tests
      // bugsnagNotify(
      //   `shouldUpdateNative - current: ${String(
      //     currentBuildNumber
      //   )}, new: ${String(newBuildNumber)}`
      // );

      // ignore until we've fetched our global settings
      return false;
    }
    return newBuildNum > currentBuildNum;
  }

  get storeBuildNumber(): number {
    if (embeddedIos()) {
      // return parseInt(this.userManager.accountData.appStoreBuildNumber);
      return this.globalSettings.appStoreBuildNumber;
    }
    if (embeddedAndroid()) {
      // return parseInt(this.userManager.accountData.playStoreBuildNumber);
      return this.globalSettings.playStoreBuildNumber;
    }
    return 0;
  }

  // for now these are both just synonyms for 'apple review mode'
  get accountCreationDisabled(): boolean {
    return (
      this.userManager.purchaseFlowDisabled || appConfig.accountCreationDisabled
    );
  }

  // get defaultToWelcome() {
  //   return (
  //     !this.userManager.authenticated &&
  //     (this.accountCreationDisabled || (embeddedMode() && this.firstVisit))
  //   );
  // }

  get defaultLandingRoute() {
    // if (this.defaultToWelcome) {
    //   return welcomePath();
    // }

    return homePath();
  }

  get disableGoogleAuth(): boolean {
    if (this.localState.googleAuthEnabled) {
      return false;
    }

    return (
      embeddedMode() ||
      appConfig.accountCreationDisabled ||
      appConfig.disableGoogleAuth
    );
  }

  async validateInvite(code: string) {
    track('account__validate_invite', { code });

    const result = await this.apiInvoker.post<{
      status: string;
      code: string;
      message: string;
    }>(
      'users/validate_invite',
      {
        code,
      },
      { networkIndicator: true }
    );

    log.info(`validate invite result: ${JSON.stringify(result)}`);
    await this.localState.storeValidatedInviteCode(result.code);
    return result;
    // return { messageKey: 'api.auth.invite_validated' };
  }

  setDebugStatus(message: string) {
    this.debugStatus = message;
  }

  clearDebugStatus() {
    this.debugStatus = null;
  }

  initializeSyncManagers() {
    log.debug(`initializeSyncManagers`);
    // firebase connection expected to be uninitialized at this stage, but provides
    // a handle to the future initialized state.
    const { firebaseConnection, caliServerInvoker } = AppFactory;

    if (AppFactory.userDataSync) {
      bugsnagNotify('userDataSync unexpectedly already initialized');
    }

    AppFactory.setUserDataSync(
      new UserDataSyncImpl({
        firebaseConnection,
        caliServerInvoker,
      })
    );

    AppFactory.setSettingsSync(
      new SettingsSyncImpl({ firebaseConnection, caliServerInvoker })
    );

    AppFactory.setCatalogMetaSync(
      new CatalogMetaSyncImpl({ firebaseConnection, caliServerInvoker })
    );
  }

  async initializeFirebaseConnection() {
    log.debug('initializeFirebaseConnection');
    const { firebaseConnection } = AppFactory;

    if (firebaseConnection.db) {
      log.error('firebase unexpectedly already initialized');
    } else {
      const status = await initializeFirebaseConnection({
        firebaseConnection,
        apiEnv: appConfig.apiEnv,
      });
      log.info(`initializeFirebase - status: ${status}`);
    }

    this.storyManager.subscribeToCatalogSlug(this.catalogSlug);

    // todo: subscribe to catalog and user data updates

    // GlobalSettings drives the native build update banner
    AppFactory.settingsSync.subscribeGlobal(data => {
      log.debug(`globalSettings updated: ${JSON.stringify(data)}`);
      applySnapshot(this.globalSettings, data);
      // defaultCatalogSlug is just hardwired for now
      // this.localState
      //   .storeDefaultCatalogSlug(data?.catalogSlug)
      //   .catch(bugsnagNotify);
    });

    if (this.userManager.authenticated) {
      this.userManager.startListen();
    }
  }

  async refreshGlobalSettings() {
    try {
      if (this.offline) {
        log.info(`refreshGlobalSettings - offline, skipping`);
        return;
      }
      const settingsData = await AppFactory.settingsSync.fetchGlobal();
      log.debug('before globalSettings applySnapshot');
      applySnapshot(this.globalSettings, settingsData);
      log.debug('after globalSettings applySnapshot');
    } catch (error) {
      if (isNetworkError(error as Error)) {
        log.warn(`refreshGlobalSettings network error: ${error} - ignoring`);
      } else {
        alertWarningError({ error, note: 'refreshGlobalSettings' });
      }
    }
  }

  onRemoteMessage(data: any) {
    log.info(`onRemoteMessage`, data);
    presentSimpleAlert(`push notification data: ${JSON.stringify(data)}`);
  }

  // async selectL2(l2: string) {
  //   log.info(`selectL2: ${l2}`);
  //   if (this.l2 !== l2) {
  //     // const catalogSlug = defaultCatalogSlugForL2(l2);
  //     const catalogSlug = this.catalogSlug;
  //     log.info(`loading catalog slug: ${catalogSlug}`);
  //     await this.userManager.overrideCatalogSlug(catalogSlug);
  //   }
  // }
}

export const getBaseRoot = (node: any): AppRoot => {
  const root = getRoot(node);
  if (root && root instanceof AppRoot) {
    return root;
  } else {
    // need fallback for soundbite stories loaded outside of catalog
    log.debug('using AppFactory.root');
    return AppFactory.root;
  }
};

// if (window.location?.search?.includes('force-embedded=t')) {
//   window.embeddedPlatform = 'ios';
// }

if (window.location?.search?.includes('force-death=t')) {
  throw Error('testing hard error during module imports');
}

if (window.location?.search?.includes('force-unhandled=t')) {
  const nonFatal = async () => {
    // eslint-disable-next-line no-console
    console.log('triggering unhandled promise rejection');
    throw Error(
      'testing unhandled promise rejection triggered during module imports'
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  nonFatal();
  // this seemed to work in a dev build, but was fatal to the standalone build
  // @armando: can you figure out how to test the unhandled promise flow in a deployed build?
  // await sleep(0);
}
